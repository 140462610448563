@import "./utilities";

#about {
  #who-skills {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  #skills-section {
    display: flex;
    flex-direction: column;
    h3 {
      align-self: center;
    }
  }

  #skills-bars-container {
    display: flex;
    flex-direction: column;
  }

  #skill-set-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $dark-grey;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 100%;
    height: 90px;
    margin: 15px 0;

    h5 {
      @extend %bold-font;
      color: $dark-grey;
      background-color: $orange;
      font-size: 1rem;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      height: 90px;
      text-align: center;
      padding: 0 0.5rem;
      border-top-right-radius: 10px;
    }
  }

  #skill-set-techs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  .skill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    transition: 0.3s;

    figcaption {
      @extend %bold-font;
      padding-top: 0.5rem;
      font-size: 0.75rem;
    }
  }

  article {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    p {
      font-size: 0.9rem;
    }
  }

  img {
    max-width: 250px;
    width: 90%;
    height: auto;
    align-self: center;
    margin-bottom: 30px;
  }

  #articles-about-me {
    .article-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: $dark-grey;
      color: white;
      font-size: 25px;
      border: solid 2px $orange;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      width: 60px;
      height: 60px;
      align-self: center;
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 350px) {
  #about .skill {
    figcaption {
      font-size: 0.6rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  #about #who-skills {
    margin-bottom: 70px;
  }

  #about #articles-about-me {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  #articles-about-me article {
    width: 25ch;
  }

  #background,
  #love-learning {
    margin-right: 5ch;
  }

  #code,
  #future-focus {
    margin-left: 5ch;
  }
}

@media only screen and (min-width: 1000px) {
  #about #who-skills {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 25px 0 25px 0;
  }

  #who {
    width: 44%;
    margin: 0;
  }

  #skills-section {
    width: 45%;
  }

  #about #articles-about-me {
    justify-content: space-between;
    width: 100%;
  }

  #articles-about-me article {
    width: 44%;
    margin-top: 25px;
  }
}

@media only screen and (min-width: 1560px) {
  #articles-about-me {
    margin-top: 50px;
  }

  #articles-about-me article {
    width: 25ch;
  }

  #background {
    margin-right: 0;
  }

  #love-learning {
    margin-left: 5ch;
    margin-right: 0;
  }
}
