@import "./utilities";

header {
  display: flex;
  flex-direction: column;
  height: 3.5rem;
  width: 100vw;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: black;
  animation: header-appear 1.2s ease;
  z-index: 1;

  @keyframes header-appear {
    0% {
      height: 0rem;
    }
    100% {
      height: 3.5rem;
    }
  }

  #navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 130ch;
  }

  #mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h6 {
      color: white;
      font-size: 1em;
      &:hover{
        color: $orange;
      }
    }

    ul {
      position: absolute;
      top: 100%;
      width: auto;
      display: none;
    }

    .mobile-menu {
      display: flex;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.8);

      li {
        padding: 0.25em;
      }
    }
  }

  #links {
    display: flex;
    flex-direction: row;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    li {
      padding: 0 0 0 1rem;
    }

    .icon {
      font-size: 1.3rem;
    }
  }

  a,
  h6 {
    @extend %bold-font;
    text-decoration: none;
    color: white;
    transition: 0.3s;
    animation: nav-appear 1.2s ease;
    &:hover {
      color: $orange;
    }
    @keyframes nav-appear {
      0% {
        color: transparent;
      }
      100% {
        color: white;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  span {
    padding: 0 0 0 0.5em;
  }

  .arrow {
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    display: inline-block;
    padding: 0.2em;
  }

  .down {
    transform: rotate(45deg);
  }

  .right {
    transform: rotate(-45deg);
  }

  #desktop-nav {
    display: none;
    ul {
      display: flex;
      flex-direction: row;
      @extend %bold-font;
    }

    li {
      padding: 0 2rem 0 0;
    }
  }
}

@media only screen and (min-width: 1000px){
  header #desktop-nav{
    display: flex;
    flex-direction: row;
  }
  header #mobile-nav{
    display: none;
  }

  header #links {
    li {
      padding: 0 0 0 2rem;
    }
  }
}
