@import-normalize;
@import "./utilities.scss";
@import "./navbar.scss";
@import "./about-me.scss";
@import "./my-work.scss";
@import "./contact.scss";
@import "./home.scss";
@import "./interests.scss";
@import "./footer.scss";

* {
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}

.sub-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 130ch;
}

.title {
  @extend %bold-font;
  font-size: 2rem;
  max-width: 60ch;
  color: black;
  margin-bottom: 80px;
  &:after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 0.3rem solid $orange;
    padding: 0.1rem;
  }
}

.heading {
  @extend %bold-font;
  text-align: justify;
  font-size: 1.1rem;
  color: black;
  line-height: 150%;
  max-width: 60ch;
  margin-bottom: 0.5rem;
}

.sub-heading {
  @extend %bold-font;
  text-align: justify;
  color: $dark-grey;
  line-height: 150%;
  max-width: 60ch;
}

.content {
  @extend %light-font;
  text-align: justify;
  line-height: 150%;
  max-width: 60ch;
}

.appear-right {
  animation: show-right 1s ease;
  @keyframes show-right {
    0% {
      transform: translateX(100vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
}

.appear-left {
  animation: show-left 1s ease;
  @keyframes show-left {
    0% {
      transform: translateX(-100vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
}

@media only screen and (min-width: 768px) {
  :root {
    font-size: 110%;
  }
}
