@import "./utilities";

#interests {
  article {
    display: flex;
    flex-direction: column;
    border: solid 1.5px $orange;
    height: 30ch;
    max-width: 30ch;

    h3 {
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      padding: 0.5rem;
      position: relative;
      top: 84%;
      margin: 0;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 0.85rem;
      padding: 0 0.5rem;
      position: relative;
      top: 84%;
      margin: 0.5rem 0;
      z-index: -1;
      opacity: 0;
    }
  }

  a {
    text-decoration: none;
    font-size: 0.9rem;
    padding: 0 0.5rem;
    position: relative;
    top: 84%;
    z-index: -1;
    opacity: 0;
  }

  .interest-url {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 1.2rem;
    color: white;
    transition: 0.3s;
    &:hover {
      color: $orange;
    }

    figcaption {
      @extend %bold-font;
      font-size: 0.9rem;
    }
  }

  #football {
    background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("../content/images/football.jpeg");
    background-position: center;
    background-size: cover;
  }

  #running {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../content/images/running.jpeg");
    background-position: center;
    background-size: cover;
  }

  #nike {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../content/images/nike.jpeg");
    background-position: center;
    background-size: cover;
  }

  #travelling {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../content/images/travelling.jpeg");
    background-position: center;
    background-size: cover;
  }

  #cooking {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../content/images/cooking.jpeg");
    background-position: center;
    background-size: cover;
  }

  #formula-1 {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../content/images/formula-1.jpeg");
    background-position: center;
    background-size: cover;
  }

  .show-article {
    top: 0%;
    opacity: 1;
    z-index: 0;
    transition: 0.3s ease-in;
  }
}

@media only screen and (min-width: 768px) {
  #interests #articles-interests {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 90ch;
  }
}

@media only screen and (min-width: 1000px) {
  #interests #articles-interests {
    margin: 25px 0 25px 0;
  }
}
