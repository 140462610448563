@import "./utilities";

#home {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../content/images/home-cover.jpg");
  background-position: center;
  background-size: cover;

  h1 {
    @extend %bold-font;
    font-size: 3rem;
    color: white;
    text-align: center;
  }

  h2 {
    @extend %bold-font;
    font-size: 2rem;
    color: white;
    text-align: center;
  }

  a {
    padding: 0.5em 0 0 0;
  }

  .arrow {
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    display: inline-block;
    padding: 0.2em;
  }

  .down {
    transform: rotate(45deg);
  }

  #credits {
    @extend %light-font;
    position: absolute;
    bottom: 0rem;
    font-size: 0.6rem;
    color: rgba(255, 255, 255, 0.3);
    a {
      @extend %light-font;
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
