@import "./utilities";

#mywork {
  background-color: $dark-grey;

  h2,
  p,
  h3 {
    color: white;
  }

  h4 {
    @extend %bold-font;
    padding: 0 0 0.5rem 0;
    color: white;
  }

  #mywork-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #project-carousel-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 60ch;
    margin-bottom: 50px;
  }

  #project-desc {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  #project-carousel {
    img {
      width: 100%;
    }

    h4 {
      color: $dark-grey;
    }
  }

  #project-buttons-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0 0 0;
    font-size: 0.9rem;

    button {
      @extend %bold-font;
      font-size: 1rem;
      padding: 0.5rem 0;
      width: 7rem;
      outline: none;
      background-color: transparent;
      color: white;
      border: 0.1rem solid white;
      transition: 0.3s;
      &:hover {
        background-color: white;
        color: $dark-grey;
      }
    }

    #previous-arrow {
      padding: 0 0.25rem 0 0;
    }

    #next-arrow {
      padding: 0 0 0 0.25rem;
    }
  }

  .arrow {
    border: solid $orange;
    border-width: 0 0.2em 0.2em 0;
    display: inline-block;
    padding: 0.2em;
  }

  .left {
    transform: rotate(135deg);
  }

  .right {
    transform: rotate(-45deg);
  }

  #tech-links-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 60ch;
  }

  #project-tech {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  #project-tech-list {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 60ch;
    border: solid 1.5px $orange;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    figure {
      flex: 1 1 0;
      text-align: center;
      font-size: 1.5rem;
      padding: 0.5rem;
      color: white;
      transition: 0.3s;
    }

    figcaption {
      @extend %bold-font;
      font-size: 0.8rem;
      padding-top: 0.2rem;
    }
  }

  #project-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #project-links-nav {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 60ch;
    border: solid 1.5px $orange;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    a {
      text-align: center;
      text-decoration: none;
      color: white;
      font-size: 1.5rem;
      padding: 0.5rem 1rem;
      transition: 0.3s;
      &:hover {
        color: $orange;
      }
    }

    figcaption {
      @extend %bold-font;
      font-size: 0.8rem;
      padding-top: 0.2rem;
    }
  }

  .description{
    font-size: 0.95rem;
    margin-top: 0.5rem;
  }
}

@media only screen and (min-width: 1000px) {
  #mywork #mywork-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 25px 0 25px 0;
  }

  #mywork #project-carousel-section {
    width: 50%;
    margin-bottom: 0;
  }

  #mywork #tech-links-section {
    width: 40%;
  }
}
