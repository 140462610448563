@import "./utilities";

#contact {
  background-color: $dark-grey;

  h2 {
    color: white;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 60ch;
    width: 100%;

    input,
    textarea {
      background-color: rgba(0, 0, 0, 0.2);
      margin: 0 0 0.5rem 0;
      border: none;
      outline: none;
      max-width: 60ch;
      width: 100%;
    }

    input {
      padding: 0.5rem 0;
    }

    textarea {
      height: 6rem;
    }

    input[placeholder],
    textarea[placeholder] {
      @extend %light-font;
      color: white;
      font-size: 0.9rem;
      padding-left: 0.5rem;
    }

    textarea[placeholder]{
      padding-top: 0.5rem;
    }

    button {
      @extend %bold-font;
      font-size: 1rem;
      padding: 0.5rem 0;
      width: 7rem;
      outline: none;
      background-color: transparent;
      color: white;
      border: 0.1rem solid white;
      transition: 0.3s;
      &:hover {
        background-color: white;
        color: $dark-grey;
      }
    }

    span{
      padding: 0 0 0 0.25rem;
    }

    .arrow {
      border: solid $orange;
      border-width: 0 0.2em 0.2em 0;
      display: inline-block;
      padding: 0.2em;
    }

    .right {
      transform: rotate(-45deg);
    }
  
    .form-message {
      @extend %bold-font;
      font-size: 1rem;
      padding: 0.5rem 0;
      color: white;
    }
  }
}

@media only screen and (min-width: 1000px) {
  #contact form {
    margin: 25px 0 25px 0;
  }
}
