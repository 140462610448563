@import "./utilities";

footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  background-color: black;

  nav {
    display: flex;
    flex-direction: row;

    a {
      text-decoration: none;
      color: white;
      transition: 0.3s;
      font-size: 1.3rem;
      padding: 0 3rem;
      &:hover {
        color: $orange;
      }
    }
  }

  .arrow {
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    display: inline-block;
    padding: 0.2em;
  }

  .up {
    transform: rotate(-135deg);
  }
}
