$green: #60654a;
$orange: #ff7c00;
$off-white: #fbf7f5;
$dark-grey: #333333;
$light-grey: #777777;
$dark-grey-transp: rgba(51,51,51,0.8);

%bold-font {
  font-family: 'Fjalla One', sans-serif;
}

%light-font {
  font-family: 'Playfair Display', serif;
}